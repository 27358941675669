<template>
<div>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header sub-page">
                <div class="content__header-left">
                    <router-link to="/" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                    </router-link>
                    <div class="label-icon blue">
                        <svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path xmlns="http://www.w3.org/2000/svg" d="M5.32812 5.46612C6.96881 3.95349 9.3448 2.99609 11.9979 2.99609C14.651 2.99609 17.027 3.95349 18.6677 5.46612" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path xmlns="http://www.w3.org/2000/svg" d="M7.55273 8.18328C8.64619 7.2759 10.2298 6.70166 11.9986 6.70166C13.7673 6.70166 15.351 7.2759 16.4444 8.18328" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path xmlns="http://www.w3.org/2000/svg" d="M9.77539 10.9995C10.3226 10.5453 11.1139 10.2582 11.9983 10.2582C12.8827 10.2582 13.675 10.5453 14.2212 10.9995" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path xmlns="http://www.w3.org/2000/svg" d="M4.40122 10.9995L3.04765 17.7663C2.71251 19.441 3.99405 21.0037 5.70276 21.0037H18.295C20.0027 21.0037 21.2842 19.441 20.9491 17.7663L19.5955 10.9995" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                    <div class="heading-wrapper">
                        <h1 class="heading-page-h1">
                            {{ device.name }}
                        </h1>
                        <ul class="heading-list" v-if="!processing">
                            <li>{{ $translate('dynamo-db-device') }}</li>
                        </ul>
                    </div>
                </div>
                <div class="content__header-right">
                    <button class="btn btn-w-shadow btn-standart-2" :class="{ processing }" @click.prevent="refreshData">
                        <inline-svg :src="require(`@/assets/img/Arrow-Rotate.svg`)" />
                        <span>{{ $translate('refresh') }}</span>
                    </button>
                </div>
            </div>

            <TableAdvanced 
                v-model="sortCol"
                :rows="filteredRows"
                :columns="columns"
                
            >
                <template v-slot:filters>
                    <div class="filter-options__search filter-options__search--m">
						<input type="search" 
							class="input bg-left search-icon" 
							:placeholder="$translate('search') + ' DeviceEUI'" 
							v-model="rowsFilters.search"
						/>
					</div>
                    <div class="filter-options__items flex-x-wrap flex-auto">
                        <div class="flex items-center">
                            <span class="select-title">{{ $translate('from-date') }}</span>
                            <DatePicker v-model="rowsFilters.from_date"></DatePicker>
                        </div>
                        <div class="flex items-center">
                            <span class="select-title">{{ $translate('to-date') }}</span>
                            <DatePicker v-model="rowsFilters.to_date"></DatePicker>
                        </div>
						<DropdownAdvanced
							selectAll="Select Battery Status"
							:options="batteryStatusOptions"
							v-model="rowsFilters.battery_status"
						/>
                    </div>
                </template>
                <template v-slot:row="slotData">
                    <!-- <td>{{ slotData.r.timestamp | formatDateTime}}</td> -->
                    <template 
                        v-for="(tdItem, tdItemIndex) in columns"
                    >
                        <td v-if="tdItemIndex" :key="tdItem + '-' + tdItemIndex + '-'" >
                            {{ slotData.r.device_data[tdItem.filter] }}
                        </td>
                        <td v-else :key="tdItem + '-' + tdItemIndex + '-'" >
                            {{ slotData.r.device_data[tdItem.filter] | formatDateTime }}
                        </td>
                    </template>
                </template>
            </TableAdvanced>

            <div class="processing" v-if="processing" style="min-height: 300px;"></div>
        </main>
        <Pagination v-model="filteredRows" :items="rawSource" :dots="true" />
    </div>
</div>
</template>

<script>
import { API, Auth } from "aws-amplify";
import { getDevice } from "@/graphql/queries";
import { devStatus, devicesDataCategories } from "@/config";

import Base from "@/components/base";
import Tabs from "@/components/tabs";
import Popups from "@/components/popups";
import MeasurmentsChart from "@/components/charts/MeasurmentsChart";
import VueMomentsAgo from 'vue-moments-ago';

import { mixTables } from '@/mixins';

export default {
    name: "DevicesOverviewDynamo",
    props: ["id"],
    mixins: [mixTables],
    components: {
        ...Base,
        ...Tabs,
        ...Popups,
        MeasurmentsChart,
        VueMomentsAgo,
    },

    data() {
        return {
            source: 'devices',
            searchField: 'device_eui',
            processing: false,
            device: {},
            backup: {},
            categories: devicesDataCategories,
            batteryStatusOptions: [
                { id: 'Good', name: 'Good' },
                { id: 'Bad', name: 'Bad' }
            ],
            updateKey: 0,
            iotData: [],
            columns: [
                { name: 'Timestamp', filter: 'Timestamp', disableCheckbox: true },
                { name: 'Device Eui', filter: 'device_eui'},
                { name: 'Temperature External', filter: 'temperature_external'},
                { name: 'Temperature Internal', filter: 'temperature_internal'},
                { name: 'Humidity', filter: 'humidity'},
                { name: 'Lon', filter: 'lon'},
                { name: 'Lat', filter: 'lat'},
                { name: 'Battery Value', filter: 'battery_value'},
                { name: 'Battery Status', filter: 'battery_status'},
            ]
            //filteredRows: [],
        };
    },
    computed: {
        deviceStatus: {
            get() {
                return devStatus[this.device.status];
            },
            set(bool) {
                this.device.status = devStatus[bool];
            },
        },
        activeTab: {
            get() {
                return this.$route.query.tab || "overview";
            },
            set(tab) {
                this.$router.push({ query: { tab } });
            },
        },
        isActiveTab() {
            return (tab) => (tab === this.activeTab ? "active" : "");
        },
        sortedIOT() {
			return this.iotData.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
		},
        sourceData() {
            return this.sortedIOT
        }
    },
    methods: {
        switchTab(tab) {
            this.activeTab = tab;
        },
        async refreshData() {
            this.processing = true;

            const device = await API.graphql({
                query: getDevice,
                variables: {
                    id: this.id,
                },
            }).then((res) => res.data.getDevice);


			let path = `/items/${device.device_iot_data_id}`;
			let myInit = {
				headers: {
					"Content-Type" : "application/json",
					Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
				},
			};

			this.iotData = await API.get('stripeapi', path, myInit);

            this.device = { ...device };
            this.backup = JSON.parse(JSON.stringify(device));

            this.processing = false;
            this.updateKey++;
        },
    },
    async created() {
        await this.refreshData();
    },
};
</script>
